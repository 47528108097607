// 准考证查询
import {
  getRequest,
  deleteRequest,
  patchRequest
} from "@/libs/axios";
// 分页查询
export const getZkz = params => {
  return getRequest("/yethan/ticketBank/listPage", params);
};
// 删除
export function deleteZkz(sid) {
  return deleteRequest('/yethan/ticketBank/' + sid);
}
// 准考证详情查单个

export function getZkzDetails(sid) {
  return getRequest('/yethan/ticketBank/' + sid);
}

// 查准考证详情以及所有科目
export function getZkzSubject(params) {
  return getRequest('/yethan/ticketBank', params);
}
// 测试权限
export function userStatus(cscx, sfzjh, zkzkid) {
  return patchRequest(`/yethan/ticketBank/${cscx}/${sfzjh}/${zkzkid}`);
}
