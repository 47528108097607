<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import ChangeZkzk from "@/components/change-Zkzk.vue";
  import CjConditionSearch from "@/components/form/cjConditionSearch.vue";
  import {
    getZkzk,
    getZkzInfo,
    refreshData
  } from "@/api/printCard/printCard.js";

  import {
    getZkz,
    deleteZkz,
    userStatus,
  } from "@/api/printCard/printCardInfo.js";

  /**
   *准考证库管理
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      ChangeZkzk,
      // CjConditionSearch
    },
    data() {
      return {
        title: "准考证库  /  ",
        title2: "2021年二级造价工程师职业资格增报专业考试准考证库",
        subTitle: "切换库",
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "准考证库",
            active: true,
          },
        ],
        years: [],
        tableList: [],
        zkzk: {},
        examList: [],
        sid: "",
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          ksnf: new Date().getFullYear(),
          zkzkid: "",
          keyName: "",
          keyWord: "",
          zkzyxz: ""
        },
        exportTitle: "准考证信息导出",
        module: "ZKZKDC",
        keyName: [{
            name: "姓名",
            val: "ksxm",
          },
          {
            name: "考生号",
            val: "ksh",
          },
          {
            name: "身份证号",
            val: "zjhm",
          },
        ],
        printstatus: [{
          name: "已打印",
          val: "true",
        }, {
          name: "未打印",
          val: "false",
        }, ],
        conditionList: [{
            name: "民族",
            type: "select",
            val: "mzm",
            glzdb: "dict_mz",
          },
          {
            name: "证件号码",
            type: "input",
            val: "zjhm",
          },
          {
            name: "考生号",
            type: "input",
            val: "ksh",
          },
          {
            name: "性别",
            type: "radio",
            val: "xb",
            // glzdb: "dict_xb",
          },
          {
            name: "科目1成绩",
            type: "input",
            val: "cj1",
          },
          {
            name: "科目2成绩",
            type: "input",
            val: "cj2",
          },
          {
            name: "科目3成绩",
            type: "input",
            val: "cj3",
          },
          {
            name: "科目4成绩",
            type: "input",
            val: "cj4",
          },
          {
            name: "科目5成绩",
            type: "input",
            val: "cj5",
          },
          {
            name: "科目6成绩",
            type: "input",
            val: "cj6",
          },
          {
            name: "科目7成绩",
            type: "input",
            val: "cj7",
          },
          {
            name: "科目8成绩",
            type: "input",
            val: "cj8",
          },
          {
            name: "科目9成绩",
            type: "input",
            val: "cj9",
          },
        ],
        zkzrs: "",
        zkzyxz: "",
        zkzRate: ""
      };
    },
    methods: {
      // 准考证分页
      getList() {
        getZkz(this.pageData).then((res) => {
          if (res.status) {
            this.tableList = res.data;
            this.pageData.total = res.total;
          }
        });
      },
      // 准考证库详情
      getZkzkDeatails(sid) {
        getZkzInfo(sid).then((res) => {
          if (res.status) {
            this.title2 = res.data.ksmc;
            this.mbh = res.data.mbh;
            this.zkzrs = res.data.zkzrs ? res.data.zkzrs : 0
            this.zkzyxz = res.data.zkzyxz ? res.data.zkzyxz : 0
            if (this.zkzrs && this.zkzrs != 0) {

              this.zkzRate = Number((this.zkzyxz / this.zkzrs).toFixed(2)) * 100;
            }
          }
        });
      },
      searchClick() {
        //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页
        this.getList(); //渲染数据的函数
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },

      //模板预览
      mouldPreview(item) {
        if (item.cscx) {
          let token = localStorage.getItem('accessToken')
          item.token = token
          item.zkzkid = this.sid
          item.zkzkmbh = this.mbh
          window.open(this.REGISTER_URL + "/ticketSearch?data=" + JSON.stringify(item), '_blank');
          return;
        }
        let routeUrl = this.$router.resolve({
          path: "/admin/printCardPreview",
          query: {
            sid: this.sid,
            userSfzh: item.sfzjhEnc,
            mbh: this.mbh,
          },
        });
        if (this.mbh) {
          window.open(routeUrl.href, "_blank");
        } else {
          this.$message({
            type: "warning",
            message: "请先创建准考证样式！",
          });
        }
      },

      // 更多条件
      getListByCondition(e) {
        this.pageData = {
          ...this.pageData,
          ...e,
        };
        this.$refs.cjConditionSearch.hide();
        this.getList();
      },

      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的准考证数据, 是否继续?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            deleteZkz(sid).then((res) => {
              if (res.status) {
                this.$message({
                  type: "success",
                  message: "数据删除成功!",
                });
              }
              this.getList();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      // 改变启用状态
      changeQyzt(obj) {
        userStatus(obj.cscx, obj.sfzjhEnc, this.pageData.zkzkid).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "状态修改成功！",
            });
          }
        });
      },
      // 导出打印记录
      exportPrintRecord() {
        let url
        url = this.API_URL + "/yethan/ticketBankRecord/ticketStatisExport/" + this.pageData.zkzkid +
          "?ytoken=" +
          this.getStore(
            'accessToken')

        window.open(url)
      },
      // 刷新打印记录
      getRefresh() {
        this.$confirm('数据刷新可能时间较长，是否确认刷新（刷新期间请耐心等待）？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          refreshData(this.pageData.zkzkid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据统计成功!'
              });
              this.getZkzkDeatails(this.sid)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消刷新'
          });
        });
      },
      // 获取local的准考证库
      getLocalZkzk() {
        this.zkzk = this.getStore("Zkzk");
        if (this.sid) {
          this.pageData.zkzkid = this.sid;
          this.getZkzkDeatails(this.sid);
          this.getList();
          //取sid
        } else if (this.zkzk) {
          // 取locacal
          let zkzk = JSON.parse(this.zkzk);
          this.pageData.zkzkid = zkzk.sid;
          this.sid = zkzk.sid;
          this.getZkzkDeatails(this.sid);
          this.getList();
        } else {
          // 取第一个数据
          let search = {
            nf: new Date().getFullYear(),
          };
          getZkzk(search).then((res) => {
            if (res.status && res.data.length) {
              if (!this.pageData) {
                this.pageData = {};
              }
              this.pageData.zkzkid = res.data[0].sid;
              this.sid = res.data[0].sid;
              this.title2 = res.data[0].ksmc;
              this.getZkzkDeatails(this.sid);
              this.getList();
            }
          });
        }
      },
    },

    mounted() {
      this.getLocalZkzk();
    },
    created() {
      this.sid = this.$route.query.sid ? this.$route.query.sid : "";
    },
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="title2" :subTitle="subTitle" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1">
                <el-select class="mr-2" style="width: 8%" clearable placeholder="打印状态" v-model="pageData.zkzyxz"
                  size="small">
                  <el-option v-for="(item, i) in printstatus" :label="item.name" :value="item.val" :key="i">
                  </el-option>
                </el-select>
                <el-select class="mr-2" style="width: 8%" clearable placeholder="查询类型" v-model="pageData.keyName"
                  size="small">
                  <el-option v-for="(item, i) in keyName" :label="item.name" :value="item.val" :key="i">
                  </el-option>
                </el-select>
                <input placeholder="关键字" class="h30 w-10 form-control border-blue mr-2" maxlength="50"
                  v-model="pageData.keyWord" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick">
                  <i class="iconfont mr-2 icon-mb-search"></i>查询
                </button>
                <!-- <b-button variant="outline-info" class="flexList w-md mr-2 condition"
                  @click="$refs.cjConditionSearch.show(conditionList)"><i
                    class="iconfont icon-fasfa-chevron-circle-down mr-2"></i>更多条件</b-button> -->
                <a :href="'/admin/printCardImportMatch?sid=' + this.sid">
                  <b-button variant="outline-info" class="flexList mr-2 condition"><i
                      class="iconfont icon-riLine-upload-2-line mr-2"></i>准考证库导入</b-button>
                </a>

                <span class="mr-3">已打印数量:<span class="text-info">{{zkzyxz}}</span></span>
                <span class="mr-3">总数量:{{zkzrs}}</span>
                <span class="mr-3">打印率: <span class="text-warning">{{zkzRate}}%</span></span>

                <button type="button" class="btn btn-warning h30 flexList mr-2" @click="getRefresh()">
                  刷新统计
                </button>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="exportPrintRecord">
                  打印记录导出
                </button>

              </div>
              <div class="d-flex">
                <div class="border-blue export-tab" @click="
                    $exportModal().show({
                      title: exportTitle,
                      type: 'xlsx',
                      module: module,
                      condition: pageData,
                    })
                  ">
                  <i class="iconfont icon-antOutline-file-excel mr-2"></i>excel
                </div>
                <div class="border-blue export-tab" @click="
                    $exportModal().show({
                      title: exportTitle,
                      type: 'dbf',
                      module: module,
                      condition: pageData,
                    })
                  ">
                  <i class="iconfont icon-data mr-2"></i>dbf
                </div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%">序号</th>
                    <th style="width: 5%">姓名</th>
                    <th style="width: 9%">证件号</th>
                    <th style="width: 3%">性别</th>
                    <th style="width: 9%">档案号</th>
                    <th style="width: 6%">准考证号</th>
                    <th style="width: 5%">考场号</th>
                    <th style="width: 4%">座位号</th>
                    <th style="width: 5%">考点名称</th>
                    <th style="width: 8%">考点地址</th>
                    <th style="width: 6%">考试日期</th>
                    <th style="width: 6%">考试时间</th>
                    <th style="width: 7%">招录机关</th>
                    <th style="width: 6%">内设机构</th>
                    <th style="width: 5%">打印状态</th>
                    <th style="width: 5%">测试状态</th>
                    <th style="width: 5%">打印预览</th>
                    <th style="width: 4%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>
                      <div class="blue-font">{{obj.ksxm}}</div>
                    </td>
                    <td>{{obj.zjhm}}</td>
                    <td>
                      {{ obj.xb }}
                    </td>
                    <td>{{ obj.dah }}</td>
                    <td>{{ obj.zkzh }}</td>
                    <td>{{ obj.kch }}</td>
                    <td>{{ obj.zwh }}</td>
                    <td>{{ obj.kdmc }}</td>
                    <td>{{ obj.kddz }}</td>
                    <td>{{ obj.ksrq }}</td>
                    <td>{{ obj.kssj }}</td>
                    <td>{{ obj.zljg }}</td>
                    <td>{{ obj.lsjg }}</td>
                    <td class="text-center"><span v-if="obj.zkzyxz" class="text-success">已打印</span>
                      <span v-else class="text-danger">未打印</span>
                    </td>
                    <td>
                      <b-form-checkbox v-model="obj.cscx" switch class="switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td class="tab-icon">
                      <i class="iconfont icon-graphic mr-1" @click="mouldPreview(obj)"></i>
                    </td>
                    <td class="tab-icon">
                      <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj.sid,obj.ksxm )"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <!-- 切换准考证库 -->
    <ChangeZkzk></ChangeZkzk>

    <!-- 更多组合条件 -->
    <!-- <CjConditionSearch ref="cjConditionSearch" @search="getListByCondition"></CjConditionSearch> -->
    <!-- 弹窗结束 -->
  </Layout>
</template>
