// 准考证库管理
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "@/libs/axios";
// 带分页查询
export const getZkzk = params => {
  return getRequest("/yethan/ticketBankRecord/listPage", params);
};
// 不带分页的查询
export const getZkzKNopage = params => {
  return getRequest("/yethan/ticketBankRecord/list", params);
};
// 添加
export const addZkz = params => {
  return postRequest("/yethan/ticketBankRecord", params);
};
// 修改
export const editZkz = params => {
  return putRequest("/yethan/ticketBankRecord", params);
};
// 删除数据
export function deleteZkz(sid) {
  return deleteRequest("/yethan/ticketBankRecord/" + sid);
}
// 或取准考证库详情
export function getZkzInfo(sid) {
  return getRequest("/yethan/ticketBankRecord/getInfo/" + sid);
}
// 清空准考证库
export function clearZkz(zkzid) {
  return deleteRequest("/yethan/ticketBank/clearBank?zkzkid=" + zkzid);
}

// 刷新准考证打印统计
export function refreshData(zkzid) {
  return putRequest("/yethan/ticketBankRecord/ticketPrinStatis/" + zkzid);
}
// 解压照片
export function decompZkzImg(fileId, zkzid) {
  return postRequest(`/yethan/ticketBankRecord/unzipTicketBankPhoto/${fileId}/${zkzid}`);
}

// 清空时间设置
export function clearZkzkTime(sid, field) {
  return putRequest(`/yethan/ticketBankRecord/updateZkzkTime/${sid}/${field}`);
}
